.table-top{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}

table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table-head{
    text-align: center;
    padding: 10px;
}

.table-row:hover{
    background-color: #DDDCDC;
}

.cells{
    text-align: center;
    padding: 10px;
    border-top: 1px solid #9A9292;
}

.table-search{
    padding: 3px;
    border-radius: 5px;
    border: #8E8E8E 1px solid;
    width: 300px;
    height: 30px;
    background-color: #F7F7F7;
}

.left-container{
    display: flex;
    gap: 10px;
    align-items: center;
}

.right-container{
    display: flex;
    width: 330px;
    justify-content: right;
    gap: 15px;
}

.container-buttons{
    display: flex;
    width: 100px;
    right: 0px;
}

.button1{
    border: black 1px solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0px 10px;
    height: 30px;
}

.button2{
    border: black 1px solid;
    border-left: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0px 10px;
    height: 30px;
}