progress::-webkit-progress-bar {
   background-color: #e7e6e6;
   width: 92%;
}
progress::-webkit-progress-value {
   background-color: #DDBB21;
   width: 92%;
}

body {
   overflow: auto;
 }
 
 .main-container {
   height: 100vh; /* or any height that fits your design */
   overflow-y: auto;
 }
.button-container {
   display: flex;
}

.button-container button {
   width: calc(50% + 7px); 
   margin-right: -1px; 
   margin-top: 10px;
}

.grid-cols-3 {
   position: relative;
}

.arrow-container {
   position: relative;
   display: inline-flex;
   align-items: center;
}

.arrow-left, .arrow-right {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   width: 0;
   height: 0;
   border-style: solid;
   cursor: pointer;
}

.arrow-left {
   left: 10px; /* Adjust this value to move the left arrow closer to the middle */
}

.arrow-right {
   right: 10px; /* Adjust this value to move the right arrow closer to the middle */
}

.arrow-left::before, .arrow-right::before {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 8px 0 8px 12px;
   border-color: transparent transparent transparent #DDBB21;
   transition: border-color 0.3s;
}

.arrow-left::before {
   transform: translate(-50%, -50%) rotate(180deg); /* Rotate the arrow */
}

.arrow-right::before {
   transform: translate(-50%, -50%);
}

.arrow-left:hover::before, .arrow-right:hover::before {
   border-color: transparent transparent transparent #f1dc8f;
}
