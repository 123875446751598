@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --light-bg: #D7D9DC;
  --light-secondary-bg: #9E9E9E;
  --light-text: #202123;

  --dark-bg: #000000;
  --light-secondary-bg: #EBEDEF;
  --dark-text: #ffffff;
}

*::-webkit-scrollbar {
  width: 0.8em;
}


*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}


*::-webkit-scrollbar-thumb {
  outline: 1px solid slategrey;
  border-radius: 20px;
}

@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url(./assets//fonts/Verdana.ttf) format("truetype");
}

@font-face {
  font-family: "Verdana-Bold";
  src: local("Verdana-Bold"),
    url(./assets//fonts/verdana-bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
}

.custom-dropdown::after {
  content: "\2BC6";
  font-size: 1em;
  color: #000;
  position: absolute;
  top: 45%;
  right: 16px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 0;
}

.custom-shadow {
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.loader {
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  line-height: 1.4em;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  color: #0000;
  text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
    calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
    calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
    calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
    calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  animation: l20 2s infinite linear;
}

.loader:before {
  content: "Loading...";
}

@keyframes l20 {
  9.09% {
    text-shadow: calc(0 * var(--w)) -10px #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  18.18% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) -10px #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  27.27% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -10px #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  36.36% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) -10px #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  45.45% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) -10px #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  54.54% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) -10px #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  63.63% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) -10px #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  72.72% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) -10px #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  81.81% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) -10px #000, calc(-9 * var(--w)) 0 #000;
  }

  90.90% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) -10px #000;
  }
}