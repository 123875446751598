progress::-webkit-progress-bar {
    background-color: #e7e6e6;
    width: 85%;
  }
  
  progress::-webkit-progress-value {
    background-color: #DDBB21;
    width: 92%;
  }
  
  .button-container {
    display: flex;
  }
  
  .button-container button {
    width: calc(50% + 7px);
    margin-right: -1px;
  }
  
  .grid-cols-3 {
    position: relative;
  }
  
  .arrow-container {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .arrow-left {
   left: 10px;
   top: calc(50% - 7px); 
   border-width: 8px 7px 8px 0; 
   border-color: transparent #000 transparent transparent; 
   transform: rotate(360deg);
  }
  
  .arrow-right {
   right: 10px;
   border-width: 9px 0 9px 7px;
   border-color: transparent transparent transparent #000;
  }
  
  .arrow-left::before, .arrow-right::before {
   content: "";
   position: absolute;
   top: 50%;
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 8px 0 8px 12px;
   border-color: transparent transparent transparent #9a9999;
   transition: border-color 0.3s;
  }
  
  .arrow-left:hover::before, .arrow-right:hover::before {
   border-color: transparent transparent transparent #efefef;
  }
  
  /* Custom button and container styles */
  .custom-button {
    border: 1px solid #e3b51a;
    border-radius: 16px;
    padding: 16px;
    text-align: center;
    background-color: #E3b51a;
    color: white;
    transition: all 0.3s;
  }
  
  .custom-button:hover {
    color: #e3b51a;
    background-color: #fefefe;
  }
  
  .custom-container {
    background-color: white;
    padding: 40px;
    border-radius: 16px;
    border: 1px solid black;
    width: 100%;
    overflow-x: auto;
  }
  
  .custom-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .custom-subtitle {
    font-size: 18px;
    font-weight: normal;
  }
  
  .custom-progress-bar {
    width: 100%;
    height: 12px;
    transition: width 0.3s;
  }
  