@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap");
.main {
  background: linear-gradient(180deg, #dfe4f2 39.5%, #ffffff 100%);
}

.card {
  background-image: url("../Login/assest/rectangle-bg.png");
  box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.1) inset;
  background-position: -2px 0.5px;
  border-radius: 29px;
  box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.14);
}

.bottom-circle {
  background: linear-gradient(
    250deg,
    rgba(234, 182, 70, 0.21) 0%,
    rgba(234, 182, 70, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px #00000040;
}
.right-circle {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(217, 217, 217, 0.45) 100%
  );

  box-shadow: 0px 4px 4px 0px #00000040;
}

.left-circle {
  background: linear-gradient(
    120deg,
    rgba(217, 217, 217, 0.5) 10%,
    rgba(234, 196, 70, 0.5) 100%
  );

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.register-card {
  background-image: url("../Login/assest/register-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-position: -2px 0.5px;
  box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.1) inset;
  border-radius: 29px;
  box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.14);
}

.register-icons{
  position: absolute;
  left: 85px;

}

.input-fields{
  text-align: left;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  position: absolute;
  left: 130px;
  top: 1px;
  background-color: #f0f1f8;
  height: 2.5rem;
  padding: 0 0.8rem;
  border-radius: 1.25rem;
}

.input-field-error{
  color: red;
  position: absolute;
  font-size: 11.5px;
  font-weight:350;
  top: 63px;
  text-align: left;
  left: 120px;
}

.login-field-error{
  top: 75px;
}

.custom-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: rgba(234, 182, 70, 1);
  width: 180px;
  height: 50px;
  border-radius: 29px;
}
.register-right-circle {
  background: linear-gradient(
    100deg,
    rgba(217, 217, 217, 0.5) 0%,
    rgba(234, 196, 70, 0.5) 105%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.register-bottom-circle {
  background: linear-gradient(
    150deg,
    rgba(217, 217, 217, 0) 20%,
    rgba(217, 217, 217, 0.45) 100%
  );

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.register-left-circle {
  background: linear-gradient(
    240deg,
    rgba(236, 190, 39, 0.2) 0%,
    rgba(251, 189, 54, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* .register-card {
  box-sizing: border-box;
  background: #edf0f6;
  box-shadow: -55.33333206176758px 55.33333206176758px 55.33333206176758px 0px
    rgba(255, 255, 255, 0.1) inset;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  border-radius: 29px;
} */

.custom-register-button {
  background: #eab646;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 29px;
  color: white;
  width: 180px;
  height: 50px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
}

.custom-label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
  font-size: 13px;
}
.main-register {
  background: #edf0f6;
}
.create-account {
  font-family: Verdana;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 30px; /* Adjust the padding as needed */
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom-bg-input {
  border: 1px solid #ffffffcc;
}
.checkmark {
  position: absolute;
  top: 1;
  left: 0;
  height: 18px; /* Adjust the height and width as needed */
  width: 18px;
  background-color: #d9d9d9;
  border-radius: 5px;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #008000; /* Change color when checkbox is checked */
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px; /* Adjust the position as needed */
  top: 3px; /* Adjust the position as needed */
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@media (max-width: 640px) {
  .input-fields {
    left: 50px;
  }

  .register-icons{
    left: 10px;
  }

  .right-circle{
    right: -180px;
  }
}

